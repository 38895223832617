import Home from "./home/Home";
import './App.css';
import Header from "./componenets-header/Header";

import Skills from "./skills/Skills";
import Qualification from "./qualification/Qualification";
import Project from "./projects/project";
import Contact from "./contact/Contact";
import Footer from "./footer/footer";
import ScrollUp from "./scrollUp/ScrollUp";

const App=() =>{
  return (
      <>
      <Header/>
          <main className='main'>
              <Home/>
              
              <Skills/>
              <Qualification/>
              <Project/>
              <Contact/>


          </main>
          <Footer/>
          <ScrollUp/>
      </>

  )


}

export default App;
