import React from 'react';

const Backend = () => {
    return (
        <div>
            <div className="skills__content">
                <h3 className="skills__title">Backend development</h3>
                <div className="skills__box">
                    <div className="skills__group">
                        <div className="skills__data">
                            <i className='bx bx-badge-check'></i>
                            <div >
                                <h3 className="skills__name"> PHP</h3>


                            </div>
                        </div>
                        <div className="skills__data">
                            <i className='bx bx-badge-check'></i>
                            <div >
                                <h3 className="skills__name"> Spring Boot</h3>

                            </div>
                        </div>
                        <div className="skills__data">
                            <i className='bx bx-badge-check'></i>
                            <div >
                                <h3 className="skills__name">MySQL </h3>

                            </div>
                        </div>
                    </div>
                    <div className="skills__group">
                        <div className="skills__data">
                            <i className='bx bx-badge-check'></i>
                            <div >
                                <h3 className="skills__name"> Java</h3>


                            </div>
                        </div>
                        <div className="skills__group">
                        <div className="skills__data">
                            <i className='bx bx-badge-check'></i>
                            <div >
                                <h3 className="skills__name">Node Js</h3>


                            </div>
                        </div>


                    </div>

                    </div>
                  
                </div>
            </div>
        </div>
    );
};

export default Backend;
