import React, {useState} from 'react';
import "./qualification.css"
const Qualification = () => {
    const [toggleState, setToggleState] = useState(1);
    const  toggleTab = (index) =>{
        setToggleState(index);
    };
    return (
        <section className="qualification section" id='qualifications'>
            <h2 className="section__title">Qualification</h2>
            <span className="section__subtitle">My personal journey</span>
            <div className="qualification__container container">
                <div className="qual__tabs">
                    <div className={toggleState === 1 ? "qual__button qual__active button--flex" : "qual__button button--flex"}
                    onClick={() => toggleTab(1)}>
<i className="uil uil-graduation-cap qual__icon"></i> Education
                    </div>
                    <div className={toggleState === 2 ? "qual__button qual__active button--flex" : "qual__button button--flex"} onClick={() => toggleTab(2)}>
                        <i className="uil uil-briefcase-alt qual__icon"></i> Experience
                    </div>
                </div>
                <div className="qual__sections">
                    <div className={toggleState === 1 ? "qual__content qual__content-active " : "qual__content "}>
                        <div className="qual__data">

                            <div>
                                <h3 className="qual__title">Mathematics Baccalaureate</h3>
                                <span className="qual__subtitle">Sousse, Kalaa Sghira</span>
                                <div className="qual__calender">
                                    <i className="uil uil-calendar-alt"></i>2019-2020
                                </div>
                            </div>
                            <div>
                                <span className="qual__rounder"></span>
                                <span className="qual__line"></span>
                            </div>
                        </div>
                        <div className="qual__data">

                            <div></div>
                            <div>
                                <span className="qual__rounder"></span>
                                <span className="qual__line"></span>
                            </div>
                            <div>
                                <h3 className="qual__title">preparatory cycle for engineering studies</h3>
                                <span className="qual__subtitle">Preparatory Institute for Engineering Studies , Monastir</span>
                                <div className="qual__calender">
                                    <i className="uil uil-calendar-alt"></i>2020-2022
                                </div>
                            </div>

                        </div>
                        <div className="qual__data">

                            <div>
                                <h3 className="qual__title">Applied computer science engineering </h3>
                                <span className="qual__subtitle">The National Engineering School of Sousse</span>
                                <div className="qual__calender">
                                    <i className="uil uil-calendar-alt"></i>2022-2025
                                </div>
                            </div>
                            <div>
                                <span className="qual__rounder"></span>
                                <span className="qual__line"></span>
                            </div>
                        </div>

                    </div>

                    <div className={toggleState === 2 ? "qual__content qual__content-active " : "qual__content "}>
                        <div className="qual__data">

                            <div>
                                <h3 className="qual__title">Web development intern using React and Node js </h3>
                                <span className="qual__subtitle">Vivia </span>
                                <div className="qual__calender">
                                    <i className="uil uil-calendar-alt"></i>july 2023
                                </div>
                            </div>
                            <div>
                                <span className="qual__rounder"></span>
                                <span className="qual__line"></span>
                            </div>
                        </div>
                        <div className="qual__data">

                            <div></div>


                        </div>


                    </div>
                </div>

            </div>

        </section>
    );
};

export default Qualification;
