import React from 'react';

const Frontend = () => {
    return (
        <div className="skills__content">
          <h3 className="skills__title">Frontend development </h3>
          <div className="skills__box">
              <div className="skills__group">
                  <div className="skills__data">
                      <i className='bx bx-badge-check'></i>
                      <div >
                          <h3 className="skills__name"> HTML</h3>


                      </div>
                  </div>
                  <div className="skills__data">
                      <i className='bx bx-badge-check'></i>
                      <div >
                          <h3 className="skills__name"> CSS</h3>


                      </div>
                  </div>
                  <div className="skills__data">
                      <i className='bx bx-badge-check'></i>
                      <div >
                          <h3 className="skills__name">Javascript </h3>


                      </div>
                  </div>
              </div>
              <div className="skills__group">
                  <div className="skills__data">
                      <i className='bx bx-badge-check'></i>
                      <div >
                          <h3 className="skills__name">Bootstrap</h3>
          

                      </div>
                  </div>
                  <div className="skills__data">
                      <i className='bx bx-badge-check'></i>
                      <div >
                          <h3 className="skills__name"> Angular </h3>


                      </div>
                  </div>
                  <div className="skills__data">
                      <i className='bx bx-badge-check'></i>
                      <div >
                          <h3 className="skills__name">REACT JS</h3>


                      </div>
                  </div>
              </div>

          </div>
        </div>
    );
};

export default Frontend;
