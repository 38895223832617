import React from 'react';
import "./footer.css"
const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer_container container">
                <h1 className="footer_title">Houda</h1>
           <ul className="footer_list">
               <li>
                   <a href="about" className="footer_link">About</a>
               </li>
               <li>
                   <a href="projects" className="footer_link">Projects</a>
               </li>
               <li>
                   <a href="#qualification" className="footer_link">qualifications</a>
               </li>
           </ul>
<div className="footer_social">
    <a href="https://github.com/Houda-2001" className="home__social-icon" target="_blank">
        <i className="uil uil-github"></i>
    </a>
    <a href="https://www.linkedin.com/in/houda-elamri/" className="home__social-icon" target="_blank">
        <i className="uil uil-linkedin-alt"></i>
    </a>

</div>
                <span className="footer_copy">all rights are reserved to Houda El Amri</span>
            </div>
        </footer>
    );
};

export default Footer;
