import React from 'react';

const Social = () => {

    return(
        <div className="home__social">
            <a href="https://github.com/Houda-2001" className="home__social-icon" target="_blank">
                <i className="uil uil-github"></i>
            </a>
            <a href="https://www.linkedin.com/in/houda-elamri/" className="home__social-icon" target="_blank">
                <i className="uil uil-linkedin-alt"></i>
            </a>
            

        </div>
    )
}

export default Social;