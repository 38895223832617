import {Typography,Card,CardContent,CardActions,CardMedia ,Box, Dialog, DialogContent} from '@mui/material';
import mediathèqueImage from './assets/Médiathèque (3).png';
import DentistImage from './assets/Blue Modern Dentist Logo.png';
import LifesaverImage from './assets/images (1).png';
import React, { useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import inscr from './assets lifesaver/page inscri final.png';
import cnx from './assets lifesaver/page conn final.png';
import map from './assets lifesaver/map fin.png';
import cpt from './assets lifesaver/le compte final.png';
import qp from './assets lifesaver/qui peut.jpeg';
import qz from './assets lifesaver/quizpage1final.png';
import qz2 from './assets lifesaver/quizpage2final.png';
import wh from './assets lifesaver/WhatsApp Image 2022-12-25 at 00.19.35.jpeg'
import tm from './assets lifesaver/temoignage.jpeg'
import { Carousel } from 'react-responsive-carousel';
import b from './assets mediatheque/bienvenue.png';
import c from './assets mediatheque/catalogue pour a.png';
import d from './assets mediatheque/catalogue des livres en tant u.png';
import f from './assets mediatheque/crud1.png';
import g from './assets mediatheque/footer.png';

import i from './assets mediatheque/home.png';
import j from './assets mediatheque/mettre a jour.png';
import k from './assets mediatheque/page connexion.png';
import l from './assets mediatheque/page dajout.png';
import m from './assets mediatheque/register shyha.png';
import n from './assets mediatheque/vd.png';

import CloseIcon from '@mui/icons-material/Close';
import "./project.css"

const Project = () => {
  const [isDialogOpen, setIsDialogOpen] = useState([false, false, false]); // Use an array for multiple dialogs

  const openDialog = (index) => {
    const updatedDialogStates = [...isDialogOpen];
    updatedDialogStates[index] = true;
    setIsDialogOpen(updatedDialogStates);
  };

  const closeDialog = (index) => {
    const updatedDialogStates = [...isDialogOpen];
    updatedDialogStates[index] = false;
    setIsDialogOpen(updatedDialogStates);
  };

  const projectData = [
    {
      title: 'Lifesaver',
      image: LifesaverImage,
      description: 'Developed a web application using HTML, CSS, JavaScript, Bootstrap, and PHP that simplifies the process of locating nearby blood donation centers through real-time geolocation. This user-friendly platform enables users to swiftly discover and access the nearest donation centers. Leveraging advanced technologies and location services, the application promotes and streamlines life-saving blood donations, serving as a crucial tool during emergencies for providing rapid medical assistance. ',
      githubLink: 'https://github.com/Houda-2001/ps1-Lifesaver.git',
      images: [qp,wh,tm,inscr, cnx,cpt, map,qz2,qz],
    },
    {
      title: 'Dentist website',
      image: DentistImage,
      description: 'Utilized a technology stack comprising HTML, CSS, JavaScript, Bootstrap, and PHP to design and create a user-friendly web application that offers a comprehensive platform for users to explore various healthcare services offered by doctors. This innovative solution not only facilitates easy scheduling of appointments but also enhances the overall experience of accessing and managing medical care.',
      images: [ ],
      noIcon: true,
    },
    {
      title: 'Online Library',
      image: mediathèqueImage,
      description: 'The proposed solution entails the creation of a comprehensive web application for effectively managing our engineering school s library. This sophisticated system, developed using Angular, PrimeNG, Spring Boot, Java, and TypeScript, provides the administrator with robust tools to oversee users and books, including features such as tracking availability, managing reservations, and more. Visitors, on the other hand, can seamlessly browse the library s collection, check book statuses, and even make advance reservations. This application represents an efficient and practical solution for streamlining library operations, ensuring students easy access to educational resources, and contributing significantly to their learning progress—all at no cost',
      githubLink: 'https://github.com/Houda-2001/ps2-library-management.git',
      images: [i,g,k,m,b,n,c,j,l,d,f],
    },
  ];

  return (
    <Box sx={{ mt: '20px' }} id='projects'>
      <h2 className="section__title">Projects</h2>
      <span className="section__subtitle">during this year</span>
      <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
        {projectData.map((project, index) => (
          <Card sx={{ maxWidth: 345 }} key={index}>
            <CardMedia sx={{ height: 140 }} image={project.image} title="project image" />
            <CardContent>
              <Typography sx={{ fontFamily: 'poppins', color: '#424B54' }} gutterBottom variant="h5" component="div">
                {project.title}
              </Typography>
              <Typography sx={{ fontFamily: 'poppins', textAlign: 'justify' }} variant="body2" color="text.secondary" className="home__description">
                {project.description}
              </Typography>
            </CardContent>
            <CardActions>
            {!project.noIcon && (
                  <>
                    {!project.noGithubIcon && (
                      <a href={project.githubLink} className="home__social-icon" target="_blank">
                        <i className="uil uil-github"></i>
                      </a>
                    )}
                    <a onClick={() => openDialog(index)} className="home__social-icon" style={{ cursor: 'pointer' }}>
                      <i className='bx bx-photo-album'></i>
                    </a>
                  </>
                )}
            </CardActions>
            <Dialog  fullScreen open={isDialogOpen[index]} onClose={() => closeDialog(index)}>
              <DialogContent>
                <div className="carousel-overlay">
                  <div className="carousel">
                  <Carousel showArrows={true} showThumbs={false} width='100%'>
                      {project.images.map((image, imageIndex) => (
                        <div key={imageIndex}>
                          <img src={image} alt={`Image ${imageIndex + 1}`} />
                        </div>
                      ))}
                    </Carousel>
                    <button onClick={() => closeDialog(index)}><CloseIcon/></button>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default Project;
